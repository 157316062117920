:root {
  --grey: #767676;
  --lightGrey: #EBEBEB;
  --white: #FFFFFF;
}

.sb-add-event-border-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  border-radius: 8px;
  padding: 16px;
  border-style: solid;
  border-color: var(--lightGrey);
  border-width: 1px;
  background-color: var(--white);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.sb-add-event-border-container span {
  cursor: pointer !important;
}

.tag-container {
  position: absolute;
  top: 104px;
  right: 32px;
  display: flex;
  align-items: center;
  background-color: var(--surfaceSuccessLight);
  /* Light green background */
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}