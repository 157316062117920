.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.rc-tooltip-arrow {
  left: 26px !important;
}

.rht-body-inner-content {
  margin-left: var(--width-sidebar) !important;
  width: var(--width-without-sidebar);

}

.forMobile {
  display: none;
}

.StripeElement--empty {
  border: 2px solid var(--lightGrey);
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;

}
.StripeElement--focus{
  border: 1px solid var(--midBlue);
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
}

.StripeElement--complete{
  border: 1px solid var(--midBlue);
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
}

.StripeElement--invalid{
  border: 1px solid var(--red);
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
}

.rsw-editor {
  min-height: 157px;
}

.rsw-dd {
  background-color: transparent;
}

.rsw-dd button, select
{
  margin-right: 5px;
}

.react-datepicker__navigation--previous {
  top: 10px;
  bottom: 10px;
}

.react-datepicker__navigation--next {
  top: 10px;
  bottom: 10px;
}

.react-datepicker {
  font-weight: "400";
  border-radius: 10px !important;
  border: none;
  margin-bottom: 10px;
}

.react-datepicker__current-month {
  font-size: 1rem;
  margin-top: 32px;
  font-weight: "400";
  color: var(--midnightBlue);
}

.react-datepicker__day{
  color: var(--midnightBlue);
}

.react-datepicker__day-name {
  color: var(--midnightBlue);
}

/* Header */
.react-datepicker__header {
  background-color: var(--monochrome-white);
  border-bottom: none;
  padding-top: 0;
  position: relative;
}

/* Month and Year between navigation buttons */
.react-datepicker__current-month {
  font-size: 1rem;
  margin-top: 32px;
  font-weight: "400";
}

.rc-slider-mark-text {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  font: Poppins-Medium;
  color: var(--midBlue);
  line-height: 24px;
  text-decoration: none;
  transform: translateX(-50%);
  position: absolute;
  /* right: 0; */

}

.rc-slider-handle {
  border: none !important;
}

.rc-slider-dot{
  border: none !important;
  background-color: none !important;
}

.rsw-ce ul {
  list-style: disc;
  padding-left: 2em;
}

.rsw-ce ol {
  list-style: decimal;
  padding-left: 2em;
}

[x-placement="bottom-start"] .dropdown-menu {
  position: absolute !important;
  transform: translate3d(0px, 50px, 0px) !important;
}

[x-placement="top-start"] .dropdown-menu {
  position: absolute !important;
  transform: translate3d(0px, -50px, 0px) !important;
}

.reactEasyCrop_Container {
  /* Set a fixed width and height for the container */
  width: '100% !important';
  height: '100% !important';
  width: 700px;
  height: 393.75px;
  position: relative;
  /* Add a border to the container */
  border: 'none !important';
  border-radius: 4px;
  /* Ensure the image inside the container is not selectable */
  user-select: none;
  position: relative !important;
}

/* Style the image inside the container */
.reactEasyCrop_Container img {
  /* Ensure the image fills the container */
  width: 100%;
  height: 100%;
  /* Make the image non-selectable */
  user-select: none;
}

.page-wrapper-splash {
  position: relative;
  overflow: hidden;
  height: 100vh;
  content: "";
  background-color: var(--surfaceSecondary);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: absolute; */
  top: 0px;
  left: 0px;
  background-position: bottom;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
  width: 100%;
}

.generic-common-divider {
  border-bottom: 1px solid var(--lightGrey);
  width: 100%;
}

.generic-common-row-div-top {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.generic-common-row-div {
  display: flex !important;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.generic-common-row-centerd-div {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.generic-common-column-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.generic-common-column-centered-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.generic-common-column-div-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
}

.generic-common-column-div-half-width {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 50%;
}

.generic-common-row-div-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.generic-common-row-div-space-between-not-centered {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.account-verifications-complete-account-setup-container {
  display: flex;
  padding: 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background: var(--hoverRed);
  margin-top: 32px;
  width: 100%;
}

.event-details-top-innerMenu .generic-common-row-div .Box1 {
  min-width: 0px !important;
}

span.generic-badge-primary-button {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #61dafb;
}

.event-details-top-after-menulink-container {
  display: flex;
  flex-direction: column;
  border-bottom-color: var(--lightGrey) !important;
  border-bottom-width: 1px !important;
  padding-bottom: 40px;
  /* box-shadow: 0.3px 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.05); */
  /* padding-top: 24px; */
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--iceWhite);
  /* z-index: -1; */
}

.event-details-event-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/***CSS for desktop start***/
.ParentRow {
  background: #fbfbfb;
  padding: 60px 78px 35px !important;
  box-shadow: none;
  justify-content: space-between;
}

/* .page-wrapper {
  overflow-y: scroll !important;
} */
.Row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
  grid-gap: 20px;
  max-width: 60%;
}

.footer-logo svg {
  width: 200px;
  height: 119px;
}

/* nav.Header_nav__iMfyh {
  margin: 0;
} */
/* div#main-header {
  margin-left: 350px;
} */
.card-body div#box1 {
  position: inherit !important;
  margin-top: 16px !important;
}

img.card-img-top {
  /* height: 300px; */
  /* object-fit: cover; */
  /* object-fit: contain !important;
  background: #000; */
  /* min-height: 200px;
  max-height: 220px; */
  width: 100%;
}

.inner-tag {
  margin-top: 0px;
  padding: 0 0px;
}

/* .card-div .card-block .inner-tag div:last-child .tags-inner-container {
  border-radius: 0px 6px 0px 6px !important;
}
.card-div .card-block .inner-tag div .tags-inner-container {
  border-radius: 6px 0 6px 0px !important;
} */
/* css start 20-11-23 */
.avhguyf {
  margin-left: 0px !important;
  /* box-shadow: inset -4px 3px 8px rgba(0, 0, 0, 0.066); */
  margin-top: 0px !important;
  /* border-top: 1px solid rgb(235, 235, 235); */
}

.create-event-btnCustom {
  cursor: pointer;
  padding: 8px 12px !important;
  height: 40px;
}

.create-event-btnCustom span {
  cursor: pointer !important;
}

.inner-div {
  position: relative;
}

.event-form-div .event-description-input-container .event-description-top-aligned-input {
  height: 100%;
  width: 100%;
}

/* css end 20-11-23 */
/***CSS for desktop end***/
/****Media Query 07-11-2023 start****/
/****Media Query for mobile start****/
@media only screen and (max-width: 1199px) {
  .main-body-content {
    flex-wrap: wrap;
  }

  .home-header-sideNav {
    /* width: 35%; */
    width: var(--width-sidebar);
  }

  .rht-body-inner-content {
    margin-left: var(--width-sidebar) !important;
    width: var(--width-without-sidebar);

  }

  .main-body-content {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .event-content-div {
    margin: 0;
    /* padding: 20px; */
    padding: 0px;
  }

  .reactEasyCrop_Container {
    /* Set a fixed width and height for the container */
    width: auto;
  }

  

  .event-about-div div {
    /* display: block !important;
    padding-right: 0px !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important; */
  }

  .abcd-div {
    padding-left: 0 !important;
  }

  .event-top-container {
    display: block;
  }

  .banner-container {
    padding: 20px;
  }

  .avhguyf {
    margin: -7px 0px !important;
  }

  .banner-container span {
    padding-bottom: 10px !important;
    position: relative;
    bottom: -9px;
  }

  div#box1 {
    /* margin: 26px 0; */
  }

  .hover-bg-button {
    /* padding: 12px; */
  }

  div#box1 span {
    /* font-size: 14px !important; */
  }

  .timing-about-div div {
    /* display: block !important; */
  }

  .dob-input-container {
    width: 100% !important;
    margin: 18px 0;
  }

  .timing-about-div div {
    /* width: 100% !important; */
  }

  .timing-about-div div {
    padding-left: 0 !important;
  }

  input.form-control {
    width: 100% !important;
    margin: 18px 0;
  }

  .timing-about-div div {
    padding-right: 0 !important;
  }

  div#box1:first-child {
    /* margin-left: 17px !important; */
  }

  .icon-divv {
    margin: 31px 0 0;
    padding-left: 52px !important;
    left: 15px;
    position: relative;
  }

  .event-scroll-container {
    margin-left: 24px;
    width: 317px;
    padding-bottom: 11px;
  }

  .event-scroll-container {
    margin-left: 24px;
    width: 317px;
    padding-bottom: 11px;
  }

  .tags-inner-container {
    /* width: 182px;
    justify-content: center; */
  }

  /* button.accordion-button {
    width: 380px !important;
  } */
  .allla {
    display: block !important;
  }

  .login-box-container {
    display: block;
    width: 90%;
    margin: 30px auto;
  }

  .home-scroll-container {
    /* padding: 0 14px; */
  }

  .login-inner-box-container span {
    text-align: center !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .login-inner-box-container svg {
    width: 100%;
  }

  .Box1 {
    /* width: 90% !important;
    margin: 20px auto; */
  }

  span.login-form-label {
    text-align: left !important;
  }

  .page-wrapper {
    overflow-y: scroll !important;
  }

  .Row {
    display: inline-block;
  }

  .Box {
    background: #fff;
    padding: 0;
  }

  .ParentRow {
    display: block;
    text-align: center !important;
    background: #f1f1f1;
    padding: 60px 0 35px !important;
    box-shadow: none;
  }

  .ParentRow svg {
    width: 100%;
  }

  .Column span {
    text-align: center !important;
  }

  .Column {
    margin-left: 0;
    text-align: center !important;
    width: 100% !important;
    display: flex;
  }

  .Column span,
  .Column a {
    color: #000 !important;
  }

  .page-wrapper {
    height: auto;
  }

  .home-content-div {
    /* margin: 0; */
  }

  .sidebar {
    position: relative;
    width: 100%;
  }

  /* .Header_divHeader__wayum {
    display: block;
    text-align: center !important;
  } */
  .inner-div {
    display: flex;
    flex-direction: column !important;
  }

  .home-info-row-div {
    /* display: block; */
  }

  .sb-add-event-border-container {
    margin-left: 0 !important;
  }

  .home-info-row-div {
    display: block;
    margin: 0 15px;
    /* padding: 20px 0 0; */
  }

  /* .Header_divHeader__wayum {
    text-align: left;
  } */
  /* .Header_userDetailsContainer__HTwSt {
    margin-top: 20px;
    margin-left: 39px;
  } */
  /* nav.Header_nav__iMfyh {
    height: 96px;
    padding-top: 38px;
    margin: 24px 15px;
  } */
  /* .Header_userDetailsContainer__HTwSt {
    display: block;
  } */
  /* .Header_userDetailsContainer__HTwSt {
    display: block;
    width: 100% !important;
  } */
  .icon-div span {
    text-align: center !important;
  }

  .icon-div {
    padding: 0 !important;
  }

  /* .Header_userDetailsContainer__HTwSt {
    margin: 0;
  } */
  /* .Header_userDetailsContainer__HTwSt div {
    margin: 10px auto;
  } */
  .sidebar {
    /* height: 545px;
    background: #fff;
    overflow-y: scroll;
    padding: 0px; */
  }

  .home-content-div {
    padding-right: 0;
  }

  .home-scroll-container {
    /* display: block; */
  }

  .home-card-container {
    display: block;
  }

  .card {
    margin: 40px 20px !important;
  }

  .card-body {
    height: 214px;
  }

  .Box1 {
    /* margin: 5px 0px; */
  }

  .home-content-div span {
    /* text-align: center !important; */
  }

  .home-scroll-container div {
    /* margin-right: 0 !important; */
  }

  .tags-inner-container {
    /* margin: 10px 0; */
  }

  .tags-inner-container {
    /* margin: 0px 0;
    height: 60px; */
  }

  .tags-inner-container span {
    font-size: 19px !important;
  }

  .sb-top-dov {
    text-align: center;
  }

  .sb-options-div {
    /* display: block; */
  }

  .sb-scroll-container {
    /* justify-content: center; */
  }

  .sidebar .tags-inner-container {
    /* width: 144px !important;
    justify-content: center; */
  }

  .sb-options-div svg {
    /* text-align: center;
    margin: 10px auto; */
  }

  .sb-options-div span {
    /* margin: 28px 0 !important; */
  }

  .sb-options-div span {
    /* margin: 28px 0 !important;
    font-size: 26px !important; */
  }

  .sb-options-div {
    /* margin-top: 41px; */
  }

  .sb-indicator-view {
    /* width: 0; */
  }

  .sb-indicator-view {
    /* border: 4px solid transparent;
    display: inline-block;
    height: 1px !important;
    width: 100px; */
  }

  .sb-events-column span {
    text-align: center !important;
  }

  .sb-top-dov svg {
    /* width: 100%; */
  }

  .ParentRow div#box1 {
    margin: 0 auto;
  }

  div#main-header {
    margin-left: 0px;
  }

  .tag-div .tags-inner-container {
    width: 100%;
  }

  .card-div .tags-inner-container {
    /* width: 150px; */
  }

  .card-title.h5 {
    text-align: center;
  }

  .card-body div#box1 {
    width: 100% !important;
  }

  .card-body div#box1 {
    position: inherit !important;
    margin-top: 41px !important;
  }

  .event-about-div div:nth-child(3) {
    /* width: 100% ; */
  }

  body .react-datepicker__day-names .react-datepicker__day, body .react-datepicker__day-name{width:1.7rem !important;}

  .event-about-div button {
    /* margin-top: 10px; */
  }

  /* .dob-input-container {
    padding: 7px;
  } */
  /* .event-content-div {
  padding-bottom: 266px;
  } */
  .event-content-div div:nth-child(6) {
    padding: 0 !important;
    margin: 0 !important;
  }

  .banner-container #box1 {
    width: 100% !important;
  }

  .containerInput {
    /* padding: 14px; */
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .event-description-input-container {
    margin-top: 10px;
  }

  .event-content-div {
    margin: 0 !important;
    padding-right: 0;
  }

  .banner-container {
    margin-top: 10px;
  }

  .event-form-div {
    padding-right: 0 !important;
  }

  .event-content-div {
    padding-bottom: 40px;
  }

  .sb-add-event-container {
    justify-content: center;
  }

  .sb-scroll-container-vertical {
    height: auto;
    padding-bottom: 0px;
  }

  .sidebar {
    /* box-shadow: inherit; */
  }

  .home-header-sideNav {
    /* background: #fff; */
    /* padding-bottom: 60px; */
  }

  /* .Header_divHeader__kFlM- {
    flex-direction: column;
    padding: 0 0 0px;
  } */
  /* .Header_nav__S0AJ5 {
    height: auto;
    padding: 0px 0 0;
  } */
  /* .Header_nav__S0AJ5 {
    height: auto !important;
  } */
  /* .Header_divHeader__kFlM- {
    flex-direction: column !important;
  } */
  /* .Header_userDetailsContainer__jp4V7 {
    justify-content: center;
    padding: 15px 0 0;
  } */
  .card-div .card-block .inner-tag {
    padding: 0 10px;
    gap: 0 4px;
  }

  /* .Header_userDetailsContainer__jp4V7 .icon-div span {
    text-align: left !important;
  } */
  /* .Header_divHeader__kFlM- .inner-div {
    order: 1;
  } */
  /* .Header_divHeader__kFlM- span {
    order: 2;
  } */
  .tags-inner-container {
    /* height: 48px; */
  }

  .tags-inner-container span {
    font-size: 16px !important;
  }

  /* .Header_divHeader__kFlM- span {
    font-size: 22px !important;
  } */
  /* .Header_divHeader__kFlM- .icon-div span {
    font-size: 18px !important;
    padding: 0 0 0 8px;
  } */
  .home-card-container {
    padding-bottom: 15px;
  }

  .home-scroll-container {
    /* margin-top: 0px; */
  }

  .sidebar .sb-options-div span {
    /* font-size: 21px !important; */
  }

  .avhguyf {
    box-shadow: inherit;
    border-top: none;
    justify-content: center;
    flex-direction: column !important;
  }

  .event-scroll-container {
    overflow: inherit;
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    margin: 0;
    padding: 0 22px;
  }

  .event-scroll-container .tags-inner-container {
    width: 100%;
  }

  .event-scroll-container div {
    margin: 0px !important;
    padding: 3px;
  }

  .header-event-details {
    flex-wrap: wrap;
  }

  .timing-about-div .dob-input-container .input-dob-field {
    text-align: left !important;
    padding: 7px 12px;
  }

  .createEvnSD-btn-area .Box1 {
    margin: 0 auto 25px !important;
  }

  .event-top-container .icon-divv {
    justify-content: center !important;
    padding: 0 !important;
  }

  .event-top-container .icon-divv div {
    margin-left: 0px !important;
  }

  .event-top-container .icon-divv div div {
    width: auto !important;
    height: auto !important;
  }

  .avhguyf .menu-link-row-container {
    padding-left: 0px !important;
  }

  .event-details-event-scroll-container {
    /* overflow: inherit;
    display: grid;
    grid-template-columns: auto auto; */
  }

  .event-details-event-scroll-container .create-event-btnCustom {
    width: 100%;
    /* margin: 0 0 10px; */
  }

  .event-details-event-scroll-container .create-event-btnCustom span {
    font-size: 14px;
  }

  .summary-labeled-div div {
    /* width: 100% !important; */
  }

  .summary-labeled-div div div {
    /* flex-direction: column !important; */
  }

  .summary-labeled-div div div span {
    /* display: block;
    width: 100% !important; */
  }

  .event-details-banner-parent-content div span {
    /* font-size: 17px !important; */
  }

  .event-details-banner-parent-content div {
    /* margin-left: 0px !important; */
  }

  .event-details-banner-parent-content div div img {
    /* margin: 35px 0 0; */
  }

  .event-details-instance-component-parent-content div {
    margin: 0 0 10px;
  }

  /* .attendee-header-sideNav {
    margin-left: 0px;
  } */
  .attendee-content-div {
    margin-left: 0px;
  }

  /* .attendee-header-sideNav {
    flex-wrap: wrap;
  } */
  .rht-body-inner-content {
    padding-left: 0px;
  }

  .attendee-content-div {
    padding-right: 0px;
  }

  .attendee-content-div .menu-link-row-container span {
    padding-left: 6px;
    padding-right: 7px;
    text-align: center !important;
  }

  .attendee-parent-content {
    flex-direction: column;
    margin-top: 22px;
  }

  .attendee-parent-content div {
    /* justify-content: flex-start !important;
    margin-left: 0px !important; */
  }

  .attendee-parent-content div div {
    /* margin: 5px 0 0; */
  }

  .attendee-event-scroll-container {
    flex-direction: column;
    overflow: inherit;
  }

  .attendee-content-div {
    padding-bottom: 0px;
  }

  .hover-bg-button svg {
    /* margin: 0 auto; */
  }

  .attendee-details-header-sideNav,
  .attendee-details-content-div {
    margin-left: 0px;
    padding-right: 0px;
  }

  .attendee-details-parent-content,
  .attendee-details-header-sideNav {
    flex-direction: column;
  }

  .attendee-details-parent-content div {
    flex-direction: column !important;
    margin: 10px 0 0px !important;
  }

  .attendee-details-parent-content .Box1 {
    width: 100% !important;
  }

  .event-about-div div div:not(.Box1) {
    /* height: auto !important; */
    /* padding-left: 0px !important;
    padding-right: 0px !important;    
    width: 100% !important; */
  }

  .event-about-div div div input {
    /* padding: 7px 12px; */
    padding: 7px 6px;
  }

  .event-about-div .abcd-div .banner-container {
    padding: 12px !important;
  }

  .event-about-div .banner-container .Box1 {
    text-align: center;
  }

  .page-wrapper-home .central-modal {
    width: 95%;
  }

  .page-wrapper-home .central-modal .central-input-container {
    width: 100%;
  }

  .page-wrapper-home .central-modal .Box1 {
    margin: 0 4px !important;
  }

  .event-content-div .event-about-div div .option-container {
    position: relative;
    padding: 12px 12px 12px 35px !important;
  }

  .event-content-div .event-about-div div .option-container div span {
    margin: 0px !important;
  }

  .event-content-div .event-about-div div .option-container div {
    padding-left: 15px !important;
  }

  .event-about-div div .option-container svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0%, -50%);
  }

  .event-content-div .createEvnSD-btn-area {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .event-details-content-div {
    padding-bottom: 40px;
  }

  .sb-scroll-container {
    flex-direction: column;
  }

  .sb-scroll-container div {
    margin: 5px 0 0;
  }

  .home-info-row-div .home-scroll-container {
    /* margin: 0 0 19px; */
  }

  .home-scroll-container .create-event-btnCustom {
    /* margin: 16px auto !important; */
  }

  .home-accordion-container {
    margin-right: 0px !important;
  }

  .event-about-div .entrance-header-label {
    display: flex !important;
  }

  .event-about-div .banner-upload-block .hover-bg-button {
    /* width: 48px !important;
    height: 48px !important;
    margin: 0 auto !important; */
  }

  body .event-about-div .banner-upload-block div {
    /* height: 100% !important; */
  }

  .event-about-div .banner-upload-block .Box1 {
    height: 44px !important;
  }

  .banner-container-img {
    position: relative !important;
  }

  .event-about-div .banner-container-img .event-img-remove {
    top: 0px !important;
    right: 12px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .event-about-div .banner-container-img .event-img-remove .hover-bg-button {
    /* width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important; */
  }

  .left-tags-label-container-event-details {
    /* flex-direction: column !important; */
    align-items: center;
  }

  .left-tags-label-container-event-details div {
    /* margin-bottom: 8px; */
  }

  .summary-labeled-div .summary-header-inner-container div {
    width: auto !important;
  }

  .summary-labeled-div .summary-header-inner-container div {
    flex-direction: row !important;
  }

  .bottom-buttons-container-edit-view div#box1:first-child {
    margin-left: 0px !important;
  }

  .bottom-buttons-container-edit-view .Box1 {
    /* text-align: center;
    padding: 9px 6px; */
  }

  .summary-header-inner-container {
    /* flex-wrap: wrap; */
  }

  .event-about-div .option-container {
    position: relative;
  }

  body .event-about-div .option-container div {
    padding-left: 26px !important;
  }

  .home-header-sideNav {
    width: 100%;
    height: auto;
    position: unset;
  }

  .home-header-sideNav .sidebar.sidebar-for-mobile {
    padding: 0;
  }

  .home-header-sideNav .sidebar .mobile-menu-content .sb-top-dov {
    overflow: unset;
    height: auto;
  }

  .rht-body-inner-content {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .generic-common-row-div {
    /* flex-wrap: wrap;
    justify-content: center; */
  }

  .EventPosterUploadCont>.file-drop .file-drop-target .banner-upload-block {
    margin-bottom: 4px !important;
  }

}

/****Media Query for mobile end****/
/****Media Query for small devices start****/
.home-card-container .card-div .card-body span {
  word-break: break-all;
}

.sidebar .generic-common-row-div-space-between .generic-common-row-div {
  flex-wrap: wrap !important;
  justify-content: center;
}

.sidebar .generic-common-row-div-space-between .generic-common-row-div .generic-common-column-div {
  /* padding: 8px 0 0; */
}

@media (min-width: 821px) {

  /* .page-wrapper{
    height: 116%;
  } */
  #footer .ParentRow .Row {
    grid-template-columns: auto auto auto;
    width: 100%;
    max-width: 50%;
  }
}

/****Media Query for small devices end****/
/****Media Query for tab start****/
@media only screen and (min-device-width: 992px) and (max-device-width: 1400px) {
  .login-box-container {
    margin-top: 30px;
  }
  .reactEasyCrop_Container {
    width: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .avhguyf {
    margin: 0px 0 !important;
  }

  span.dgdwgd {
    font-size: 20px !important;
  }

  .reactEasyCrop_Container {
    /* Set a fixed width and height for the container */
    width: auto;
  }

  .event-content-div .event-scroll-container {
    /* width: 418px; */
    width: auto;
    flex-wrap: wrap;
    padding: 0 25px;
  }

  .containerInput {
    /* width: 100%;
    margin-bottom: 18px; */
  }

  /* .event-about-div div {
    padding-right: 0 !important;
  } */
  .event-about-div span {
    /* margin-bottom: 10px !important;
    position: relative;
    bottom: 6px; */
  }

  button#react-aria7663444845-\:r0\: {
    width: 393px !important;
  }

  div#box1 {
    /* margin: 5px !important; */
  }

  .event-content-div div#box1 {
    /* margin: 5px !important;
    text-align: center; */
  }

  .event-content-div div#box1 span {
    line-height: 2.3 !important;
  }

  /* .event-content-div {
    margin-left: 276px;
  } */
  .event-top-container {
    display: block;
    padding: 20px 25px;
  }

  .event-top-container div {
    justify-content: left !important;
  }

  button {
    padding: 19px;
  }

  element.style {
    display: flex;
    height: 64px;
    width: 64px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 240, 254);
    border: 1px solid rgb(235, 235, 235);
  }

  .hover-bg-button {
    /* padding: 14px; */
  }

  /* .abcd-div {
    padding: 0 !important;
  } */
  .banner-container {
    padding: 25px;
  }

  .event-about-div .banner-container {
    padding: 25px !important;
  }

  div#box1 {
    /* padding: 10px;
    margin-top: 15px !important; */
  }

  button#react-aria6246370877-\:r0\: {
    width: 395px !important;
  }

  .event-scroll-container div {
    width: 163px;
  }

  .tags-inner-container {
    /* text-align: center !important;
    justify-content: center; */
  }

  .icon-divv {
    margin: 37px 0 20px -17px;
  }

  .event-scroll-container {
    margin: 0;
  }

  /* .event-about-div div {
    display: block !important;
    padding-left: 0px !important;
  } */
  .event-about-div .banner-container .hover-bg-button {
    /* padding: 12px !important; */
  }

  .containerInput {
    padding: 14px;
  }

  button.accordion-button {
    width: 450px !important;
  }

  .allla {
    display: block !important;
  }

  .home-content-div {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    /* margin-left: 309px; */
    padding-right: 16px;
  }

  /* .page-wrapper{
  min-height:90vh;
} */
  .sidebar {
    width: 100%;
  }

  .inner-div .Box1 span {
    font-size: 11px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* .Header_userDetailsContainer__HTwSt {
    width: 194px;
  } */
  .Box1 {
    /* margin: 0 !important; */
  }

  /* .home-info-row-div {
    display: block;
  } */
  .home-scroll-container {
    /* overflow-x: scroll; */
  }

  .tags-inner-container {
    /* height: 52px;
    margin-bottom: 15px; */
  }

  .home-card-container {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .card {
    margin: 2px 0px !important;
  }

  /* .Header_divHeader__wayum {
    display: block !important;
  } */
  .inner-div {
    margin-top: 3px;
  }

  .sb-add-event-border-container {
    margin: 15px 0 !important;
  }

  /* .Header_divHeader__wayum span:first-child {
    font-size: 20px !important;
    margin-right: 15px !important;
  } */
  .Box {
    padding: 0;
  }

  .login-box-container {
    margin-left: 66px;
  }

  .Column {
    margin: 0;
  }

  .ParentRow {
    padding: 57px 55px 10px !important;
  }

  .Row {
    margin: 20px 0;
  }

  .Box1 {
    /* margin-top: 16px; */
  }

  .footer-logo svg {
    width: 118px;
    height: 103px;
  }

  .Row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
    grid-gap: 20px;
    max-width: 80%;
  }

  #footer .ParentRow .Row {
    grid-template-columns: auto auto;
  }

  .footer-logo {
    margin-top: 20px;
    margin-right: 40px;
  }

  .Box1 {
    /* margin-top: 24px; */
  }

  .page-wrapper {
    height: auto;
  }

  div#box1 span {
    font-size: 14px !important;
    margin: 0 !important;
  }

  /* div#box1 {
    width: 188px !important;
  } */
  /* .rht-body-inner-content .Header_nav__S0AJ5 div#box1 {
    width: 95px !important;
  } */
  .inner-div {
    margin-top: 14px;
  }

  /* nav.Header_nav__iMfyh {
    height: 150px;
    padding-top: 11px;
  } */
  /* nav.Header_nav__iMfyh {
    height: 150px;
    padding-top: 11px;
  } */
  span.Heading-div {
    text-align: center !important;
    width: 100% !important;
    display: block;
    margin: 9px 0 !important;
  }

  /* div#main-header {
    margin-left: 270px;
  } */
  /* .Header_userDetailsContainer__jp4V7 {
    padding: 0 0 0 0px;
    width: auto !important;
  } */
  /* .Header_divHeader__kFlM- span {
    font-size: 21px !important;
    line-height: 1.1 !important;
  } */
  /* .Header_divHeader__kFlM- .icon-div {
    padding-left: 4px !important;
  } */
  /* .Header_divHeader__kFlM- .icon-div span {
    font-size: 17px !important;
    line-height: 0.9 !important;
  } */
  .header-event-details,
  .event-details-content-div {
    /* margin-left: 276px; */
  }

  .event-details-content-div {
    padding-right: 0px;
  }

  .event-details-banner-parent-content {
    flex-direction: column;
    /* margin-left: 28px;
    margin-right: 28px; */
  }

  .event-details-banner-parent-content div {
    /* margin-left: 0px !important; */
  }

  .event-details-banner-parent-content .create-event-btnCustom {
    margin-right: 10px;
  }

  .event-details-banner-parent-content div img {
    /* margin-top: 20px !important; */
  }

  .event-details-instance-component-parent-content div {
    margin: 0 0 8px;
  }

  /* .attendee-header-sideNav {
    margin-left: 276px;
  } */
  .attendee-content-div {
    /* margin-left: 276px; */
  }

  .attendee-content-div {
    padding-right: 0px;
  }

  .attendee-parent-content {
    flex-direction: column;
    margin-top: 22px;
  }

  .attendee-parent-content div {
    /* justify-content: flex-start !important;
    margin-left: 0px !important; */
  }

  .attendee-parent-content div div {
    /* margin: 5px 0 0; */
  }

  .attendee-details-header-sideNav,
  .attendee-details-content-div {
    /* margin-left: 276px; */
    padding-right: 0px;
  }

  .attendee-details-parent-content {
    flex-direction: column;
  }

  .attendee-details-parent-content div {
    margin-right: 0px !important;
  }

  .menu-link-row-container span {
    font-size: 13px !important;
  }

  .attendee-details-content-div {
    padding-bottom: 60px;
  }

  .attendee-personal-details-parent-content {
    margin-right: 15px !important;
  }

  .event-content-div .event-about-div div .option-container {
    position: relative;
    padding: 12px 12px 12px 35px !important;
  }

  .event-content-div .event-about-div div .option-container div span {
    margin: 0px !important;
  }

  .event-content-div .event-about-div div .option-container div {
    padding-left: 15px !important;
  }

  .event-about-div div .option-container svg {
    /* position: absolute;
    left: 12px;
    top: 12px; */
  }

  .event-about-div .containerInput input {
    padding-left: 12px !important;
  }

  .add-entrance-btn div#box1 {
    width: 100% !important;
  }

  .event-about-div div .dob-input-container {
    width: 100% !important;
    margin: 4px 0 19px;
  }

  .rht-body-inner-content .inner-div .icon-div span {
    font-size: 14px !important;
  }

  .topAdmin-user-imgArea {
    width: 50px;
  }
}

/****Media Query for tab end****/
/****Media Query for ipad pro start****/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .ParentRow {
    padding: 25px 75px 15px !important;
  }

  .reactEasyCrop_Container {
    /* Set a fixed width and height for the container */
    width: auto;
  }

  .Row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
    grid-gap: 20px;
    max-width: 80%;
  }

  .Box1 {
    /* margin-top: 16px; */
  }

  /* .page-wrapper {
  min-height: 70vh !important;
  height: auto !important;
} */
}

/****Media Query for ipad pro end****/
@media only screen and (min-device-width: 1025px) and (max-device-width: 1400px) {
  .login-box-container {
    margin-top: 40px;
  }

  .footer-logo svg {
    width: 80px;
    height: auto;
  }

  .reactEasyCrop_Container {
    /* Set a fixed width and height for the container */
    width: auto;
  }
}

/****Media Query for ipad air start****/
@media only screen and (min-device-width: 820px) and (max-device-width: 940px) {
  .Row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
    grid-gap: 20px;
    max-width: 65% !important;
  }

  .reactEasyCrop_Container {
    /* Set a fixed width and height for the container */
    width: auto;
  }

  .page-wrapper {
    /* min-height: 80vh; */
    height: auto !important;
  }

  #footer .ParentRow .Row {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 1249px) {

  .live-event-statistics-left-parent-container,
  .live-event-statistics-right-parent-container {
    flex-direction: column !important;
    align-items: center;
  }

  .live-event-statistics-inner-chart-container {
    position: relative;
  }

  .event-details-centered-div-into-pie-chart-right,
  .event-details-centered-div-into-pie-chart {
    /* top: 50%;
    right: inherit;
    left: 50%;
    transform: translate(-54%, -52%); */
  }
}

@media (max-width: 1099px) {
  .Box {
    position: initial;
    margin-top: 50px;
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .live-event-statistics-parent-view {
    flex-direction: column !important;
    align-items: flex-start;
  }

  .live-event-statistics-left-parent-container,
  .live-event-statistics-right-parent-container {
    /* flex-direction: row !important; */
    /* margin: 25px 0px; */
  }
  

  
}

@media (max-width: 575px) {
  .event-scroll-container .tags-inner-container span {
    font-size: 14px !important;
  }

  .live-event-statistics-left-parent-container,
  .live-event-statistics-right-parent-container {
    flex-direction: column !important;
    margin: 25px 0px;
  }

  .live-event-statistics-parent-view {
    align-items: center;
  }

  .event-details-centered-div-into-pie-chart-right,
  .event-details-centered-div-into-pie-chart {
    /* transform: translate(-54%, -58%); */
  }

  .event-details-pie-chart-parent-content {
    background: transparent;
    box-shadow: inherit;
    border: none;
  }

  .live-event-statistics-inner-chart-container {
    padding: 15px 15px 15px 35px;
  }

  .event-details-centered-div-into-pie-chart {
    left: 53%;
    top: 49%;
  }

  .event-scroll-container .tags-inner-container span {
    font-size: 14px !important;
  }

  .live-event-statistics-left-parent-container,
  .live-event-statistics-right-parent-container {
    flex-direction: column !important;
    margin: 25px 0px;
  }

  .live-event-statistics-parent-view {
    align-items: center;
  }

  .event-details-centered-div-into-pie-chart-right,
  .event-details-centered-div-into-pie-chart {
    /* transform: translate(-54%, -58%); */
  }
}

/****Media Query for ipad air end****/
/****Media Query 07-11-2023 end****/
textarea {
  /* resize: none; */
  padding: 12px 16px;
  border-radius: 4px;
}

.CommonFormGroup {
  margin-bottom: 16px;
}

.SummaryLabeledDivEdit .CommonFormGroup {
  margin-bottom: 25px;
}

.CommonFormGroup>span:first-child {
  margin-bottom: 8px !important;
  display: inline-block;
}

.page-wrapper-event .rht-body-inner-content .TicketGuardCont .TicketGuardTitleLogo .TicketGuardLogo svg {
  display: inline-block;
}

.EventPosterUploadCont {
  margin-bottom: 32px;
}

.EventPosterUploadCont>.file-drop .file-drop-target .banner-upload-block {
  margin-bottom: 0;
  padding: 70px 0 !important;
}

.EventPosterUploadCont>.banner-container-img {
  margin-bottom: 0;
  flex: unset;
}

.SideBarEventCont .SideBarEventBox .EventImgCont {
  flex: auto 0 0 !important;
}

.SideBarEventCont .SideBarEventBox .EventImgCont>div {
  display: block !important;
  width: 48px;
}

.SalesInfoContDiv .SalesInfoCont {
  gap: 24px;
}

.SummaryBodyCont .SummaryGroup {
  gap: 16px;
}

.SummaryBodyCont .SummaryGroup .SummaryLabel {
  width: 226px;
}

.SummaryBodyCont .SummaryGroup .SummaryDetails {
  flex: 1 1 0 !important;
  gap: 16px;
  align-items: start !important;
}

.hover-bg-button {
  width: 48px !important;
  height: 48px !important;
  margin: 0 auto !important;
  border-radius: 50% !important;
}

.hover-bg-button>svg {
  height: 24px;
  width: 24px;
}

.page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .EventCategoryCont {
  margin-bottom: 16px;
}

.page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .EventCategoryCont .CommonFormGroup:last-child {
  margin-bottom: 25;
}

.LatLongMapCont {
  gap: 16px;
}

.summary-header-inner-container {
  gap: 16px;
}

.RadioRow .option-container>svg {
  min-width: 28px;
}

.central-modal-contentPopup-check .CheckTickCont {
  /* height: auto;
  width: auto; */
}

.FullPriceBodyCont .FullPriceGroup,
.StagedBodyCont .StagedGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: start;
}

.PageTopTabCont .PageTopTabScroll {
  max-width: 100%;
  overflow: auto;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

.topCreate-eventHeader {
  gap: 24px;
}

.SettingCommonBoxCont {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  /* justify-content: start;
  align-items: center; */
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background-color: var(--white);
  margin-top: 40px;
}

.CommonSpaceBetweenRow {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  gap: 24px;
  width: 100%;
}

.CommonSpaceBetweenRow .CommonSpaceBetweenCol {}

.CommonSpaceBetweenColumn {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.CommonSpaceBetweenRow .CommonSpaceBetweenCol {}

.BtnCont296 {
  width: 296px;
  max-width: 100%;
  margin-inline: auto;
}

.BtnCont360 {
  width: 360px;
  max-width: 100%;
  margin-inline: auto;
}

.Gap34 {
  gap: 34px !important;
}

.PaymentSystemIconCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  flex-wrap:wrap;
}

.PaymentSystemIconCont>img {
  border-radius: 12px;
}

.SettingPaymentFooterCont {}


.home-scroll-container {
  display: flex;
  /* overflow-x: scroll; */
  white-space: nowrap;
  flex-direction: row;
}

.home-info-row-div.homeTob-btns-tool {
  gap: 0 40px;
}

.rht-body-inner-content {
  margin-left: var(--width-sidebar);
  width: var(--width-without-sidebar);
}

/*---- CUSTOM SCROLLBAR - CSS - START ----*/
* {
  scrollbar-color: var(--brand-primary-brand) #f5f5f5;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #b9b9b9;
  background-color: var(--brand-primary-brand);
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

/*---- CUSTOM SCROLLBAR - CSS - END ----*/
.page-wrapper-fp .footer-d,
.business-signup-page-wrapper .footer-d,
.page-wrapper-login .footer-d {
  margin-left: 0;
  width: 100%;
}

.page-wrapper-fp .footer-d,
.page-wrapper-su .footer-d {
  margin-left: 0;
  width: 100%;
}

.topTitleDropdownCont {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 44px;
}

.topTitleDropdownCont .TitleCont {}

.topTitleDropdownCont .DropdownCont {}

.topTitleDropdownCont .DropdownCont>div {}

.topTitleDropdownCont .DropdownCont .dropdown {
  height: auto !important;
}

.topTitleDropdownCont .DropdownCont .dropdown button.dropdown-toggle {
  border-radius: 8px;
  border: 1px solid #DCDFE4 !important;
  padding: 8px 12px;
  height: 36px !important;
  color: #002366 !important;
  font-size: 14px;
  font-weight: 500;
}

.topTitleDropdownCont .DropdownCont .dropdown button.dropdown-toggle svg {
  margin-left: 8px;
}

.topTitleDropdownCont .DropdownCont .dropdown .dropdown-menu {
  color: #002366 !important;
  font-size: 14px;
  font-weight: 500;
}


  /* css 29-07-24 start */
  .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-lft{padding:0 0 4px;}
  .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-rht{padding:0 0 4px; min-width:290px;}

  .generic-common-row-div.personal-details-block .generic-common-column-div.personal-details-rht span{word-break: break-all;}
  .businessDetails-block .businessDetails-lft .generic-common-column-div span{word-break: break-all;}
  /* css 29-07-24 end */


  /* css start 08-08-24 start */

    .summary-header-discount .generic-common-row-div{align-items:center;}

  /* css start 08-08-24 end */



  /* css start 16-08-24 */

    .booking-fees-progress-container .rc-slider-mark .rc-slider-mark-text{position:absolute;}

  /* css end 16-08-24 */


@media screen and (max-width:1620px) {

  /* css 29-07-24 start */

.home-info-row-div.homeTob-btns-tool{}
.home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-lft{width:85%; padding:0 0 10px;}
.home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-rht{width:15%; padding:0 0 10px;}

/* css 29-07-24 end */

}


@media screen and (max-width: 991px) {
  .LatLongMapCont {
    flex-direction: column !important;
  }

  .LatLongMapCont .LatLongCont,
  .LatLongMapCont .MapCont {
    width: 100% !important;
  }

  /* css 29-07-24 start */

    
    .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-lft{width:80%; padding:0 0 8px;}
    .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-rht{width:20%; padding:0 0 8px;}

  /* css 29-07-24 end */

    /* css start 09-08-24 */
      .create-discount-code-top-div{flex-direction:column !important; align-items:flex-start;}
      .create-discount-code-top-div .Box1{margin:22px 0 0;}
    /* css end 09-08-24 */

    /* css start 09-08-24 */

      .addOn-field-block{flex-direction:column !important;}
      .addOn-field-block .CommonFormGroup{padding-left:0px !important; padding-right:0px !important;}

    /* css end 09-08-24 */

    /* css start 14-08-24 */

    .EventCategoryCont.createEvent-full-block{width:100% !important; padding-right:0px !important;}
   .page-wrapper-event .rht-body-inner-content .CommonFormFieldRow.addOn-field-block .CommonFormGroup{width:100% !important;}

    /* css end 14-08-24 */

    .full-width-col{width:100% !important;}


}


@media screen and (max-width:479px) {
  .SalesInfoContDiv .SalesInfoCont {
    flex-direction: column;
  }

  .SalesInfoContDiv .SalesInfoCont .sales-info-inner-parent-div {
    width: 100%;
  }
}



@media screen and (max-width: 767px) {
  .PageTitle {}

  .PageTitle>span {
    font-size: 24px !important;
  }

  .forMobile {
    display: inline-block;
  }

  .forDesktop {
    display: none;
  }

  .PageTitle>span {
    font-size: 24px !important;
    line-height: 48px !important;
  }

  .CommonFormGroup .containerInput,
  .CommonFormGroup .event-description-input-container {
    margin: 0 !important;
  }

  .page-wrapper-event .rht-body-inner-content .CreateEventBody .CreateEventEventTopLeft,
  .page-wrapper-event .rht-body-inner-content .generic-common-row-div-space-between,
  .TicketGuardCont .TicketGuardFormCont,
  .page-wrapper-event .rht-body-inner-content .EventRequirementsFormCont,
  .page-wrapper-event .rht-body-inner-content .RefundPolicyCont .RefundPolicyFormCont>div,
  .TicketGuardCont .TicketGuardTitleLogo,
  .CreateEventFeesCont .RadioRow,
  .central-modal .RadioRow,
  .CommonFormFieldRow,
  .page-wrapper-event .rht-body-inner-content .ticket-payment-overview-container>.generic-common-row-div,
  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .CreateEventEventTopLeft,
  .bottom-buttons-container-edit-view,
  .SummaryLabeledDivEdit .TimingBodyCont,
  .event-details-content-div .event-about-div .AddressFieldCont,
  .AddressWithMapCont,
  .MinMaxAgeCont .MinAgeCont,
  .MinMaxAgeCont .MaxAgeCont,
  .RefundPolicyFormCont .RadioRow,
  .SummaryBodyCont .SummaryGroup .SummaryDetails,
  .FullPriceBodyCont,
  .StagedBodyCont,
  .CommonSpaceBetweenRow {
    flex-direction: column !important;
  }

  .page-wrapper-event .rht-body-inner-content .CreateEventEventTopLeft .event-form-div,
  .page-wrapper-event .rht-body-inner-content .CreateEventEventTopLeft .abcd-div,
  .page-wrapper-event .rht-body-inner-content .EventCapacityCont .CapacityCont,
  .page-wrapper-event .rht-body-inner-content .AddressFieldCont .AddressLeftCont,
  .page-wrapper-event .rht-body-inner-content .AddressFieldCont .AddressRightCont,
  .page-wrapper-event .rht-body-inner-content .EventTownCityCont .TownCityCont,
  .AddressWithMapCont .AddressLatLongCont,
  .AddressWithMapCont .CreateEventMapCont,
  .page-wrapper-event .rht-body-inner-content .generic-common-row-div-space-between .central-modal-contentPopup-check,
  .page-wrapper-event .rht-body-inner-content .generic-common-row-div-space-between .MinimumAgeDropdown,
  .page-wrapper-event .rht-body-inner-content .generic-common-row-div-space-between .MaximumAgeDropdown,
  .TicketGuardCont .TicketGuardFormCont .TicketGuardFormContLeft,
  .TicketGuardCont .TicketGuardFormCont .TicketGuardFormContRight,
  .EventRequirementsFormCont .RequirementsCont,
  .TicketGuardCont .TicketGuardTitleLogo .TicketGuardTitle,
  .TicketGuardCont .TicketGuardTitleLogo .TicketGuardLogo,
  .CreateEventMaxNoOfTickets,
  .CommonFormFieldRow .CommonFormGroup,
  .page-wrapper-event .rht-body-inner-content .ticket-payment-overview-container>.generic-common-row-div .generic-common-row-div-space-between,
  .page-wrapper-event .rht-body-inner-content .CommonFormFieldRow .CommonFormGroup #box1,
  .page-wrapper-event .rht-body-inner-content .CommonFormFieldRow .CommonFormGroup .Box1,
  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .CreateEventEventTopLeft .event-form-div,
  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .CreateEventEventTopLeft .EventPosterUploadCont,
  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .EventCategoryCont,
  .SummaryLabeledDivEdit .TimingBodyCont .TimingStartEndDate,
  .SummaryLabeledDivEdit .TimingBodyCont .TimingFromToTime,
  .bottom-buttons-container-edit-view .Box1,
  .EntranceBtnCont .Box1,
  .EventDetailsEntrancesCont,
  .EventCapacityCont .CapacityCont,
  .event-details-content-div .event-about-div .AddressFieldCont .AddressLeftCont,
  .event-details-content-div .event-about-div .AddressFieldCont .AddressRightCont,
  .event-details-content-div .event-about-div .EventTownCityCont .TownCityCont,
  .MinMaxAgeCont .MinAgeCont>.central-modal-contentPopup-check,
  .MinMaxAgeCont .MinAgeCont>.MinimumAgeDropdown,
  .MinMaxAgeCont .MaxAgeCont>.central-modal-contentPopup-check,
  .MinMaxAgeCont .MaxAgeCont>.MaximumAgeDropdown,
  .FullPriceBodyCont .FullPriceGroup,
  .StagedBodyCont .StagedGroup,
  .home-content-div .home-content-div-box .home-content-div-box-page-cont {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .page-wrapper-event-details .event-details-content-div>div>.summary-labeled-div,
  .page-wrapper-event-details .event-details-content-div>div>.event-about-div,
  .EventDetailsEntrancesCont .event-about-div-bs,
  .CommonBoxCont,
  .timing-about-div.SummaryLabeledDivEdit,
  .home-content-div .home-content-div-box .home-content-div-box-page-cont .account-verified-container {
    border-radius: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .EventRequirementsFormCont .RequirementsCont {
    flex: unset !important;
  }

  .central-modal .RadioRow {
    grid-template-columns: repeat(1, 1fr) !important;

  }

  .EventRequirementsFormCont .RequirementsCont {
    flex: unset !important;
  }

  .SummaryBodyCont .SummaryGroup .SummaryDetails {
    align-items: start !important;
  }

  .SummaryBodyCont {
    width: 100% !important;
  }

  .SummaryBodyCont .SummaryGroup .SummaryLabel {
    width: 163px !important;
    max-width: 50%;
  }

  .CreateEventFeesCont .RadioRow,
  .bottom-buttons-container-edit-view,
  .SummaryBodyCont .SummaryGroup .SummaryDetails {
    gap: 16px;
  }

  .RefundPolicyFormCont .RadioRow {
    gap:20px;
  }

  .TicketGuardCont .TicketGuardTitleLogo .TicketGuardTitle {
    order: 2;
  }

  .TicketGuardCont .TicketGuardTitleLogo .TicketGuardLogo {
    order: 1;
    text-align: right;
    margin-bottom: 16px;
  }

  .TicketGuardCont .TicketGuardTitleLogo .TicketGuardLogo svg {
    display: inline-block;
  }

  .page-wrapper-event .rht-body-inner-content .RefundPolicyCont .RefundPolicyFormCont .option-container .CreateEventBody .EventCategoryCont {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }



  .page-wrapper-event .rht-body-inner-content .CreateEventBody .EventCategoryCont {
    padding-right: 0 !important;
  }

  .EventCategoryCont {
    width:  100% !important;
  }

  .PageTopTabCont {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .page-wrapper-event .rht-body-inner-content .AddressFieldCont {
    flex-direction: column !important;
  }

  .page-wrapper-event .rht-body-inner-content .AddressWithMapCont {
    flex-direction: column !important;
  }

  .AddressWithMapCont .CreateEventMapCont {
    margin-top: 32px !important;
  }



  .MinMaxAgeCont .MinAgeCont>.MinimumAgeDropdown,
  .MinMaxAgeCont .MaxAgeCont>.MaximumAgeDropdown {
    margin-top: 40px !important;
  }

  .MinMaxAgeCont .MinAgeCont {
    margin-top: 48px !important;
  }

  .page-wrapper-event .rht-body-inner-content .RefundPolicyCont .RefundPolicyFormCont .option-container:first-child {
    margin-bottom: 25px !important;
  }

  /* .MinMaxAgeCont .MinAgeCont > .central-modal-contentPopup-check {
    margin-bottom: 40px!important;
  } */
  .TicketGuardCont .TicketGuardFormCont .TicketGuardFormContLeft {
    margin-bottom: 48px;
  }

  .MinMaxAgeCont .MinAgeCont {
    margin-bottom: 56px !important;
  }

  .page-wrapper-event .rht-body-inner-content .ticket-payment-overview-container>.generic-common-row-div .generic-common-row-div-space-between {

    flex-direction: row !important;
    display: flex;
    gap: 24px;
  }

  .event-details-content-div .event-about-div .AddressFieldCont .AddressRightCont {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .CreateEventEventTopLeft .EventPosterUploadCont {
    margin-top: 0 !important;
    margin-bottom: 12px !important;
  }

  .page-wrapper-event-details .event-details-content-div>div>.SummaryLabeledDivEdit .CreateEventEventTopLeft .EventPosterUploadCont {
    margin-top: 0 !important;
  }

  .PaymentSystemIconCont>img {
    height: 40px !important;
    width: auto !important;
    border-radius: 6px;
  }

  .BtnCont296,
  .BtnCont360 {
    width: 100%;
  }

  .home-scroll-container {
    /* overflow-x: scroll; */
    /* padding-bottom: 10px; */
  }

  .home-scroll-container.homeTob-btns-tool-lft {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .generic-common-row-div {
    align-items: flex-start;
  }


  .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-lft{width:100%; padding:0 0 4px;}
  .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-rht{width:100%; padding:0 0 4px;}
  
  .generic-common-column-div .generic-common-row-div-space-between .CommonFormGroup {
      padding-left: 0px !important;
      width: 100% !important;
      padding-right: 0px !important;
  }

  

}



@media (min-width: 768px) and (max-width: 1024px) {
  .page-wrapper-event .rht-body-inner-content .TicketGuardCont .tags-inner-container.create-event-btnCustom {
    height: auto !important;
  }

  .page-wrapper-event .rht-body-inner-content .TicketGuardCont .tags-inner-container.create-event-btnCustom>span {
    margin-bottom: 0 !important;
    display: block;
    bottom: 0;
  }

  .page-wrapper-event .rht-body-inner-content .CommonFormFieldRow .CommonFormGroup {
    width: 50% !important;
  }
   /* css 29-07-24 start */

    
   .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-lft{width:70%; padding:0 0 7px;}
   .home-info-row-div.homeTob-btns-tool .homeTob-btns-tool-rht{width:30%; padding:0 0 7px;}
   .home-info-row-div.homeTob-btns-tool {
    gap: 0 17px;
}

 /* css 29-07-24 end */


    /* css 08-08-24 start */

      .summary-header-discount {
        flex-direction: column !important;     
        align-items: flex-start !important;
      }

    /* css 08-08-24 end */

}


@media (min-width:768px) and (max-width:1249px) {
  .SummaryBodyCont .SummaryGroup .SummaryDetails {
    flex-direction: column !important;
  }

  .summary-labeled-div .SummaryBodyCont {
    width: 100% !important;
  }

  .EventChartCont {
    max-width: 100%;
  }

  .EventChartCont>div:first-child {
    max-width: 100%;
  }

  .EventChartCont>div>div {
    max-width: 100%;
  }

  .EventChartCont>div>div>div>div {
    max-width: 100%;
  }

  .EventChartCont .ChartDetailsCont>span {
    font-size: 14px !important;
  }

  .SalesInfoContDiv .SalesInfoCont {
    flex-direction: column;
  }
}


@media(max-width:575px){

     /* css 08-08-24 start */

     .summary-header-discount {
      flex-direction: column !important;     
      align-items: flex-start !important;
    }

  /* css 08-08-24 end */

  /* css start 16-08-24 */

    .event-details-ticletSales-btns{flex-direction:column;}
    .event-details-ticletSales-btns .Box1{margin-right:0px !important; margin-top:15px !important;}
    .event-details-top-after-menulink-container span{font-size:25px !important;}

  /* css start 16-08-24 */

}


@media(max-width:380px) {

  .PageTitle>span {
    font-size: 22px !important;
  }

  .topCreate-eventHeader div#box1 span {
    font-size: 15px !important;
  }

}