.ticket-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
}

.ticket-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--midnightBlue);
}

.ticket-payment-overview-container {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--hoverFadeBlue);
  margin-top: 32px;
}

/* .stage-release-parent-box .stage-release-name-currency-parent-box span, .stage-release-parent-box .generic-common-row-div-space-between span{font-size:15px !important;} */

.event-about-div.CommonBoxCont .generic-common-row-div-space-between{flex-wrap: wrap;  width: 100%; margin:30px 0 0; column-gap:0%;}
.event-about-div.CommonBoxCont .generic-common-row-div-space-between .CommonFormFieldRow{margin:0 !important; width:48% !important;}
.accordion .generic-common-row-div-space-between-not-centered{margin-top:11px !important;}

.event-about-div.CommonBoxCont .CommonFormGroup {
  margin-bottom: 0px;
}

.stage-release-parent-box .generic-common-row-div-space-between .currencyTicket-item{margin-top:0px !important;}

@media only screen and (max-width:1024px) and (min-width:768px)  {

  body .stage-release-parent-box .generic-common-row-div-space-between.stage-release-date-parent-box .stage-release-date-child-box{width: 100% !important; 
     column-gap: 11px;}
  
}



@media (max-width:1099px){
  .accordion .RadioRow{flex-wrap:wrap; margin:7px 0 0;}
  .accordion .RadioRow .option-container{width:100%; margin:9px 0 !important;}
 

  .stage-release-parent-box{margin:0;}
.stage-release-parent-box .stage-release-name-currency-parent-box{margin:0; flex-wrap:wrap;}
.stage-release-parent-box .stage-release-name-currency-parent-box .stage-release-name-currency-child-box{margin:17px 0 0 !important; width:100% !important; padding:0 !important;
  flex:auto !important;
}
.stage-release-parent-box .stage-release-name-currency-parent-box .stage-release-name-currency-child-box .containerInput{margin:0 !important;}

.stage-release-parent-box .generic-common-row-div-space-between.stage-release-date-parent-box{margin:0; column-gap:6%; margin:16px 0 0 !important; padding:0px !important; row-gap:11px;}

.stage-release-parent-box .generic-common-row-div-space-between.stage-release-date-parent-box .stage-release-date-child-box{

  margin: 0px !important;
  padding: 0px !important;
  row-gap: 9px;
  width: 100% !important;
  flex-direction: row !important;
  column-gap: 5%;
}

.stage-release-parent-box .generic-common-row-div-space-between.stage-release-date-parent-box .stage-release-date-child-box div{margin:0 !important;}

.stage-release-parent-box .generic-common-row-div-space-between{flex-wrap:wrap;}

.stage-release-parent-box .generic-common-row-div-space-between .currencyTicket-item{width:100% !important; flex: auto !important; margin: 0 0 20px;
  padding: 0 !important;}

  .stage-release-parent-box .generic-common-row-div-space-between .currencyTicket-item .containerInput{margin-top:0px !important;}

  .generic-common-row-div-space-between.ticket-price-parent-box{
    width: 100% !important;
    flex: 1 1 100% !important;
    padding-right: 0 !important;
  }

}



@media (max-width:1024px){

  .event-about-div.CommonBoxCont .generic-common-row-div-space-between .CommonFormFieldRow{width:100% !important;}

 


  

}



@media (max-width:767px){

  .event-about-div.CommonBoxCont .CommonFormGroup {
    margin-bottom: 10px;
  }

  .event-about-div.CommonBoxCont .generic-common-row-div-space-between{margin:11px 0 0;}
  .generic-common-row-div-space-between.ticket-price-parent-box{
    width: 100% !important;
    flex: 1 1 100% !important;
    padding-right: 0 !important;
  }

}